export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  Json: { input: any; output: any }
  NaiveDateTime: { input: string; output: string }
}

export type Address = {
  street: Scalars["String"]["output"]
  zipCode: Scalars["String"]["output"]
}

export type AggregateRating = {
  averageRating: Scalars["Float"]["output"]
  count: Scalars["Int"]["output"]
}

export type AuthenticateWithEmailInput = {
  allowNotificationsEmailsMarketing?: InputMaybe<Scalars["Boolean"]["input"]>
  email: Scalars["String"]["input"]
  /** sends the OTP code by SMS instead */
  sendPhoneOtp?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type AuthenticateWithEmailPayload = {
  isFirstLogin: Scalars["Boolean"]["output"]
  phoneMasked: Maybe<Scalars["String"]["output"]>
  status: Scalars["String"]["output"]
}

export type AuthenticateWithPhoneInput = {
  phone: Scalars["String"]["input"]
}

export type AuthenticateWithPhonePayload = {
  isFirstLogin: Scalars["Boolean"]["output"]
  status: Scalars["String"]["output"]
}

export type AuthenticateWithSocialInput = {
  allowNotificationsEmailsMarketing?: InputMaybe<Scalars["Boolean"]["input"]>
  firstName?: InputMaybe<Scalars["String"]["input"]>
  jwt: Scalars["String"]["input"]
  lastName?: InputMaybe<Scalars["String"]["input"]>
  locale?: InputMaybe<Scalars["String"]["input"]>
}

export type AuthenticateWithSocialPayload = {
  data: LoginResponse
}

export type BillingInformation = {
  defaultPaymentSource: Maybe<Scalars["String"]["output"]>
  paymentSources: PaymentSourceConnection
}

export type BillingInformationPaymentSourcesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type BoundingBoxInput = {
  maxLatitude: Scalars["Float"]["input"]
  maxLongitude: Scalars["Float"]["input"]
  minLatitude: Scalars["Float"]["input"]
  minLongitude: Scalars["Float"]["input"]
}

export type CancelReservationInput = {
  preferredPolicy?: InputMaybe<RefundPolicy>
  reason: RefundReason
  reservationId: Scalars["String"]["input"]
  userFeedback?: InputMaybe<Scalars["String"]["input"]>
}

export type CancelReservationPayload = {
  data: Reservation
}

export type CheckInReservationPayload = {
  status: Scalars["String"]["output"]
}

export type CheckOutReservationPayload = {
  status: Scalars["String"]["output"]
}

export type City = {
  active: Maybe<Scalars["Boolean"]["output"]>
  boilerplateContent: Maybe<Scalars["Json"]["output"]>
  coordinates: Maybe<Coordinates>
  country: Scalars["String"]["output"]
  currency: Scalars["String"]["output"]
  distance: Maybe<Scalars["Float"]["output"]>
  featured: Scalars["Boolean"]["output"]
  /** @deprecated 16/10/2023 */
  flexibleTimeFee: Maybe<Scalars["Int"]["output"]>
  holidays: Maybe<Array<Holiday>>
  id: Scalars["String"]["output"]
  /** @deprecated 01/11/2023 - use the labels field instead */
  label: Maybe<Scalars["String"]["output"]>
  labels: Array<Label>
  languages: Maybe<Array<Scalars["String"]["output"]>>
  lowestPrice: Maybe<Scalars["Int"]["output"]>
  luggagePrice: Maybe<Scalars["Int"]["output"]>
  mainStore: Maybe<StorageSpot>
  marketingLanguages: Array<Maybe<Scalars["String"]["output"]>>
  name: Scalars["String"]["output"]
  nearestStore: Maybe<StorageSpot>
  paFeatured: Scalars["Boolean"]["output"]
  pois: Maybe<Array<Poi>>
  priority: Scalars["Int"]["output"]
  prismicId: Maybe<Scalars["String"]["output"]>
  query: Maybe<Scalars["String"]["output"]>
  region: Maybe<Region>
  slug: Scalars["String"]["output"]
  state: Maybe<Scalars["String"]["output"]>
  storeCount: Scalars["Int"]["output"]
  tier: Scalars["Int"]["output"]
  timezone: Scalars["String"]["output"]
  translations: Translations
}

export type CityConnection = {
  edges: Array<CityEdge>
  pageInfo: PageInfo
}

export type CityEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: City
}

export enum CityOrderByField {
  Featured = "FEATURED",
  PaFeatured = "PA_FEATURED",
  Priority = "PRIORITY",
}

export type CitySortOrder = {
  direction?: InputMaybe<SortOrderDirection>
  field: CityOrderByField
}

export enum CommissionType {
  Monetary = "MONETARY",
  Percentage = "PERCENTAGE",
}

export type CompletePhoneRegistrationInput = {
  email: Scalars["String"]["input"]
  firstName: Scalars["String"]["input"]
  lastName: Scalars["String"]["input"]
}

export type CompletePhoneRegistrationPayload = {
  data: User
}

export type CompleteReviewInput = {
  feedback?: InputMaybe<Scalars["String"]["input"]>
  flags?: InputMaybe<Array<Scalars["String"]["input"]>>
  id: Scalars["String"]["input"]
  rating: Scalars["Int"]["input"]
  reasons?: InputMaybe<Array<ReviewReason>>
}

export type CompleteReviewPayload = {
  creditsTransaction: Maybe<CreditsTransaction>
  status: Scalars["String"]["output"]
}

export type Coordinates = {
  latitude: Scalars["Float"]["output"]
  longitude: Scalars["Float"]["output"]
}

export type CoordinatesInput = {
  latitude: Scalars["Float"]["input"]
  longitude: Scalars["Float"]["input"]
}

export type Country = {
  country: Scalars["String"]["output"]
  currency: Scalars["String"]["output"]
  lowestPrice: Scalars["Int"]["output"]
  luggagePrice: Scalars["Int"]["output"]
}

export type Coupon = {
  code: Scalars["String"]["output"]
  id: Scalars["String"]["output"]
  type: Maybe<CouponType>
  value: Maybe<Scalars["Int"]["output"]>
}

export enum CouponStatus {
  CouponCityNotAllowed = "COUPON_CITY_NOT_ALLOWED",
  CouponExpired = "COUPON_EXPIRED",
  CouponHasntStartedYet = "COUPON_HASNT_STARTED_YET",
  CouponLimitExceeded = "COUPON_LIMIT_EXCEEDED",
  CouponMinAmountNotReached = "COUPON_MIN_AMOUNT_NOT_REACHED",
  CouponMinDaysNotReached = "COUPON_MIN_DAYS_NOT_REACHED",
  CouponNotFound = "COUPON_NOT_FOUND",
  CouponOnlyValidForHotels = "COUPON_ONLY_VALID_FOR_HOTELS",
  CouponOnlyValidForNewStores = "COUPON_ONLY_VALID_FOR_NEW_STORES",
  CouponOnlyValidForUsersWithMarketingConsent = "COUPON_ONLY_VALID_FOR_USERS_WITH_MARKETING_CONSENT",
  CouponOwnerNotAuthorized = "COUPON_OWNER_NOT_AUTHORIZED",
  CouponPerProductQuantityNotReached = "COUPON_PER_PRODUCT_QUANTITY_NOT_REACHED",
  CouponPlatformNotAllowed = "COUPON_PLATFORM_NOT_ALLOWED",
  CouponReservationSourceExcluded = "COUPON_RESERVATION_SOURCE_EXCLUDED",
  CouponServiceNotAllowed = "COUPON_SERVICE_NOT_ALLOWED",
  CouponStoreLimitExceeded = "COUPON_STORE_LIMIT_EXCEEDED",
  CouponStoreNotAllowed = "COUPON_STORE_NOT_ALLOWED",
  CouponSupportedOnAppsOnly = "COUPON_SUPPORTED_ON_APPS_ONLY",
  CouponUserLimitExceeded = "COUPON_USER_LIMIT_EXCEEDED",
  MonetaryCouponMustMatchStoreCurrency = "MONETARY_COUPON_MUST_MATCH_STORE_CURRENCY",
  OnlyCreditsCouponsCanBeRedeemed = "ONLY_CREDITS_COUPONS_CAN_BE_REDEEMED",
  PleaseRedeemCouponFirst = "PLEASE_REDEEM_COUPON_FIRST",
  Valid = "VALID",
}

export enum CouponType {
  Credits = "CREDITS",
  Monetary = "MONETARY",
  Percentage = "PERCENTAGE",
}

export type CreateReservationConversationInput = {
  reason?: InputMaybe<Scalars["String"]["input"]>
  reservationId: Scalars["String"]["input"]
}

export type CreateReservationConversationPayload = {
  conversation: Maybe<SupportConversation>
}

export type CreditsBalance = {
  amount: Scalars["Int"]["output"]
  amountWithConversion: Maybe<Scalars["Int"]["output"]>
  currency: Scalars["String"]["output"]
}

export type CreditsTransaction = {
  amount: Scalars["Int"]["output"]
  currency: Scalars["String"]["output"]
  expiresAt: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  name: Maybe<Scalars["String"]["output"]>
}

export type DeleteBagPictureInput = {
  fileId: Scalars["String"]["input"]
  reservationId: Scalars["String"]["input"]
}

export type DeleteBagPicturePayload = {
  status: Scalars["String"]["output"]
}

export type DeleteUserPayload = {
  status: Scalars["String"]["output"]
}

export enum DisplayStatus {
  Closed = "CLOSED",
  ClosedAtCheckIn = "CLOSED_AT_CHECK_IN",
  ClosedAtCheckOut = "CLOSED_AT_CHECK_OUT",
  MaxDaysExceeded = "MAX_DAYS_EXCEEDED",
  Open = "OPEN",
  OutOfCapacity = "OUT_OF_CAPACITY",
}

export type EditReservationInput = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  checkOutDate?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  products?: InputMaybe<Array<LuggageProductInput>>
  reservationId: Scalars["String"]["input"]
}

export type EditReservationPayload = {
  reservation: Reservation
}

export type File = {
  externalId: Scalars["String"]["output"]
  id: Scalars["String"]["output"]
  type: Scalars["String"]["output"]
}

export type GeneratePaymentMethodSetupIntentPayload = {
  clientSecret: Scalars["String"]["output"]
}

export type GmbAddress = {
  addressLines: Scalars["String"]["output"]
  administrativeArea: Maybe<Scalars["String"]["output"]>
  locality: Maybe<Scalars["String"]["output"]>
  postalCode: Maybe<Scalars["String"]["output"]>
  regionCode: Scalars["String"]["output"]
}

export type Holiday = {
  country: Scalars["String"]["output"]
  date: Scalars["Date"]["output"]
  id: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
  states: Maybe<Array<Scalars["String"]["output"]>>
  types: Array<Scalars["String"]["output"]>
}

export type Label = {
  metadata: Maybe<Scalars["Json"]["output"]>
  name: Scalars["String"]["output"]
}

export type LinkCouponToUserInput = {
  couponCode: Scalars["String"]["input"]
}

export type LinkCouponToUserPayload = {
  status: Scalars["String"]["output"]
}

export type Locker = {
  id: Maybe<Scalars["String"]["output"]>
  title: Maybe<Scalars["String"]["output"]>
}

export type LockerCode = {
  code: Scalars["String"]["output"]
  type: Scalars["String"]["output"]
}

export type LockerDropOffInput = {
  lockerDoor: Scalars["String"]["input"]
  reservationId: Scalars["String"]["input"]
}

export type LockerDropOffPayload = {
  status: Maybe<Scalars["String"]["output"]>
}

export type LockerPickupInput = {
  lockerDoor: Scalars["String"]["input"]
  reservationId: Scalars["String"]["input"]
}

export type LockerPickupPayload = {
  status: Maybe<Scalars["String"]["output"]>
}

export enum LockerStatus {
  Cancelled = "CANCELLED",
  CheckedIn = "CHECKED_IN",
  CheckedOut = "CHECKED_OUT",
  New = "NEW",
}

export type Lockers = {
  dropOffCode: Scalars["String"]["output"]
  lockerDoor: Maybe<Scalars["String"]["output"]>
  pickupCode: Maybe<Scalars["String"]["output"]>
  status: LockerStatus
  type: ProductType
}

export type LockersAvailability = {
  count: Maybe<Scalars["Int"]["output"]>
  size: Maybe<Scalars["String"]["output"]>
}

export type LoginResponse = {
  accessToken: Scalars["String"]["output"]
  expiry: Scalars["Int"]["output"]
  refreshToken: Maybe<Scalars["String"]["output"]>
}

export type LuggageProduct = {
  dimensions: Maybe<Scalars["String"]["output"]>
  price: Scalars["Int"]["output"]
  quantity: Scalars["Int"]["output"]
  serviceFee: Scalars["Int"]["output"]
  type: ProductType
}

export type LuggageProductInput = {
  quantity: Scalars["Int"]["input"]
  type: ProductType
}

export type LuggageStorageCommission = {
  /** @deprecated 2024-08-27 */
  commission: Maybe<StoreCommission>
  walkInBonus: Maybe<WalkInBonus>
}

export type LuggageStorageCommissions = {
  currency: Maybe<Scalars["String"]["output"]>
  exclusive: Maybe<LuggageStorageCommission>
  nonExclusive: Maybe<LuggageStorageCommission>
}

export type Microsite = {
  city: Maybe<City>
  domain: Scalars["String"]["output"]
  poi: Maybe<Poi>
}

export type MicrositeConnection = {
  edges: Array<MicrositeEdge>
  pageInfo: PageInfo
}

export type MicrositeEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Microsite
}

export type MoveReservationToAnotherStoreInput = {
  newStoreId: Scalars["String"]["input"]
  reason?: InputMaybe<RefundReason>
  reservationId: Scalars["String"]["input"]
}

export type MoveReservationToAnotherStorePayload = {
  nonce: Scalars["String"]["output"]
  reservation: Reservation
}

export type NotificationCategory = {
  billing: Maybe<Scalars["Boolean"]["output"]>
  info: Maybe<Scalars["Boolean"]["output"]>
  marketing: Maybe<Scalars["Boolean"]["output"]>
  reminders: Maybe<Scalars["Boolean"]["output"]>
  transactional: Maybe<Scalars["Boolean"]["output"]>
}

export type Notifications = {
  emails: NotificationCategory
  push: NotificationCategory
  sms: NotificationCategory
}

export type OpeningHour = {
  closeTime: Scalars["String"]["output"]
  dayOfWeek: Scalars["Int"]["output"]
  openTime: Scalars["String"]["output"]
}

export type OpeningHourException = {
  closeTime: Maybe<Scalars["String"]["output"]>
  closed: Scalars["Boolean"]["output"]
  endDate: Scalars["Date"]["output"]
  holidayId: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  openTime: Maybe<Scalars["String"]["output"]>
  reason: Maybe<Scalars["String"]["output"]>
  startDate: Scalars["Date"]["output"]
}

export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["String"]["output"]>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"]
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["String"]["output"]>
}

export type PartnershipInfo = {
  freeDays: Maybe<Scalars["Int"]["output"]>
  partnership: Maybe<PartnershipType>
}

export enum PartnershipType {
  Hotel = "HOTEL",
}

export type Payment = {
  amount: Scalars["Int"]["output"]
  currency: Scalars["String"]["output"]
  id: Scalars["String"]["output"]
  paymentMethod: Maybe<PaymentMethod>
  paymentProvider: Scalars["String"]["output"]
  status: Scalars["String"]["output"]
  usdExchangeRate: Maybe<Scalars["Float"]["output"]>
}

export type PaymentMethod = {
  brand: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  last4: Maybe<Scalars["String"]["output"]>
}

export type PaymentSource = {
  brand: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  last4: Maybe<Scalars["String"]["output"]>
  type: Maybe<Scalars["String"]["output"]>
}

export type PaymentSourceConnection = {
  edges: Array<PaymentSourceEdge>
  pageInfo: PageInfo
}

export type PaymentSourceEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: PaymentSource
}

export type PlaceGuestReservationIntentInput = {
  paymentMethodId?: InputMaybe<Scalars["String"]["input"]>
  paymentReceiver?: InputMaybe<ReservationPaymentReceiver>
  paypalPaymentMethodNonce?: InputMaybe<Scalars["String"]["input"]>
  quotedAmount?: InputMaybe<Scalars["Int"]["input"]>
  reservation: ReservationInput
  reservationSource?: InputMaybe<ReservationSource>
  triggerEsimOffer?: InputMaybe<Scalars["Boolean"]["input"]>
  useCredits?: InputMaybe<Scalars["Boolean"]["input"]>
  user: UserInput
  waiveServiceFee?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type PlaceGuestReservationIntentPayload = {
  firstBooking: Maybe<Scalars["Boolean"]["output"]>
  nonce: Maybe<Scalars["String"]["output"]>
  reservation: Reservation
  stripeIntentSecret: Maybe<Scalars["String"]["output"]>
}

export type PlaceReservationIntentInput = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate: Scalars["NaiveDateTime"]["input"]
  checkOutDate: Scalars["NaiveDateTime"]["input"]
  couponCode?: InputMaybe<Scalars["String"]["input"]>
  locale?: InputMaybe<Scalars["String"]["input"]>
  paymentMethodId?: InputMaybe<Scalars["String"]["input"]>
  paymentReceiver?: InputMaybe<ReservationPaymentReceiver>
  products?: InputMaybe<Array<LuggageProductInput>>
  quotedAmount?: InputMaybe<Scalars["Int"]["input"]>
  referralCode?: InputMaybe<Scalars["String"]["input"]>
  reservationSource?: InputMaybe<ReservationSource>
  services?: InputMaybe<Array<StorageServiceInput>>
  storeId?: InputMaybe<Scalars["String"]["input"]>
  triggerEsimOffer?: InputMaybe<Scalars["Boolean"]["input"]>
  useCredits?: InputMaybe<Scalars["Boolean"]["input"]>
  userId?: InputMaybe<Scalars["String"]["input"]>
  waiveServiceFee?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type PlaceReservationIntentPayload = {
  firstBooking: Maybe<Scalars["Boolean"]["output"]>
  nonce: Maybe<Scalars["String"]["output"]>
  reservation: Reservation
  stripeIntentSecret: Maybe<Scalars["String"]["output"]>
}

export type Poi = {
  active: Scalars["Boolean"]["output"]
  boilerplateContent: Maybe<Scalars["Json"]["output"]>
  city: City
  coordinates: Coordinates
  distance: Scalars["Float"]["output"]
  id: Scalars["String"]["output"]
  labels: Array<Label>
  /** @deprecated 27/04/2022 */
  mainStorageSpot: Maybe<StorageSpot>
  mainStore: Maybe<StorageSpot>
  marketingLanguages: Array<Scalars["String"]["output"]>
  name: Scalars["String"]["output"]
  /** @deprecated 27/04/2022 */
  nearestStorageSpot: Maybe<StorageSpot>
  nearestStore: Maybe<StorageSpot>
  priority: Scalars["Int"]["output"]
  query: Maybe<Scalars["String"]["output"]>
  slug: Scalars["String"]["output"]
  /** @deprecated 27/04/2022 */
  storageSpotCount: Scalars["Int"]["output"]
  storeCount: Scalars["Int"]["output"]
  tier: Scalars["Int"]["output"]
  translations: Translations
  type: PoiType
}

export type PoiStorageSpotCountArgs = {
  distance?: InputMaybe<Scalars["Float"]["input"]>
}

export type PoiStoreCountArgs = {
  distance?: InputMaybe<Scalars["Float"]["input"]>
}

export type PoiConnection = {
  edges: Array<PoiEdge>
  pageInfo: PageInfo
}

export type PoiEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Poi
}

export enum PoiType {
  Airport = "AIRPORT",
  AmusementPark = "AMUSEMENT_PARK",
  Beach = "BEACH",
  BusStation = "BUS_STATION",
  EventVenue = "EVENT_VENUE",
  Mall = "MALL",
  Monument = "MONUMENT",
  Museum = "MUSEUM",
  Neighborhood = "NEIGHBORHOOD",
  NotableStreet = "NOTABLE_STREET",
  Other = "OTHER",
  Park = "PARK",
  Port = "PORT",
  Stadium = "STADIUM",
  SubwayStation = "SUBWAY_STATION",
  TouristAttraction = "TOURIST_ATTRACTION",
  TownSquare = "TOWN_SQUARE",
  TrainStation = "TRAIN_STATION",
  University = "UNIVERSITY",
}

/**
 * Contains information about the pricing for this store, namely:
 * - `basePrice` : Base luggage storage price before any other pricing modifiers are applied.
 * - `finalPrice` : Final price, after pricing modifiers are applied to the store's base price.
 * - `multiplier` : The dynamic pricing multiplier that was applied to the base price.
 * - `modifiers` : List of pricing modifiers applied to the base price.
 */
export type Pricing = {
  basePrice: Scalars["Int"]["output"]
  finalPrice: Scalars["Int"]["output"]
  modifiers: Array<Scalars["String"]["output"]>
  multiplier: Scalars["Float"]["output"]
}

export enum ProductType {
  BagCompact = "BAG_COMPACT",
  BagOddsize = "BAG_ODDSIZE",
  BagStandard = "BAG_STANDARD",
  LockerLarge = "LOCKER_LARGE",
  LockerMedium = "LOCKER_MEDIUM",
  LockerSmall = "LOCKER_SMALL",
}

export type ReconciledStoragePriceQuote = {
  newPriceQuote: Maybe<StoragePriceQuote>
  reconciledPriceQuote: Maybe<StoragePriceQuote>
}

export type RedeemCouponInput = {
  couponCode: Scalars["String"]["input"]
}

export type RedeemCouponPayload = {
  status: Scalars["String"]["output"]
}

export type RefreshTokenInput = {
  refreshToken: Scalars["String"]["input"]
}

export type RefreshTokenPayload = {
  data: LoginResponse
}

export type RefundBreakdown = {
  breakdown: Maybe<Array<StoragePriceQuotePaymentBreakdown>>
  currency: Maybe<Scalars["String"]["output"]>
  refundPolicy: RefundPolicy
  reservation: Reservation
}

export enum RefundPolicy {
  ManualRefund = "MANUAL_REFUND",
  NoRefund = "NO_REFUND",
  RefundSamePaymentMethod = "REFUND_SAME_PAYMENT_METHOD",
  RefundToCredits = "REFUND_TO_CREDITS",
}

export enum RefundReason {
  CantFindLocker = "CANT_FIND_LOCKER",
  CantFindStore = "CANT_FIND_STORE",
  /** @deprecated redundant */
  ChangeOfPlans = "CHANGE_OF_PLANS",
  ExpectedLockers = "EXPECTED_LOCKERS",
  FoundAnotherService = "FOUND_ANOTHER_SERVICE",
  LocationTooFar = "LOCATION_TOO_FAR",
  LockerUnavailable = "LOCKER_UNAVAILABLE",
  MyBagsCannotFitInTheLocker = "MY_BAGS_CANNOT_FIT_IN_THE_LOCKER",
  Other = "OTHER",
  PaidWithCashInstead = "PAID_WITH_CASH_INSTEAD",
  StoreClosed = "STORE_CLOSED",
  TestBooking = "TEST_BOOKING",
  UncomfortableLocation = "UNCOMFORTABLE_LOCATION",
  /** @deprecated redundant */
  UseAnotherLocation = "USE_ANOTHER_LOCATION",
  WrongBagNumber = "WRONG_BAG_NUMBER",
  WrongDates = "WRONG_DATES",
  WrongNumberOfLockerDoors = "WRONG_NUMBER_OF_LOCKER_DOORS",
}

export enum Region {
  Africa = "AFRICA",
  Asia = "ASIA",
  CentralAmerica = "CENTRAL_AMERICA",
  Europe = "EUROPE",
  MiddleEast = "MIDDLE_EAST",
  NorthAmerica = "NORTH_AMERICA",
  Oceania = "OCEANIA",
  SouthAmerica = "SOUTH_AMERICA",
}

export type RegisterDeviceTokenInput = {
  deviceToken: Scalars["String"]["input"]
}

export type RegisterDeviceTokenPayload = {
  status: Scalars["String"]["output"]
}

export type RegisterUserInput = {
  allowNotificationsEmailsMarketing?: InputMaybe<Scalars["Boolean"]["input"]>
  email: Scalars["String"]["input"]
  firstName?: InputMaybe<Scalars["String"]["input"]>
  lastName?: InputMaybe<Scalars["String"]["input"]>
  locale?: InputMaybe<Scalars["String"]["input"]>
  phone?: InputMaybe<Scalars["String"]["input"]>
}

export type RegisterUserPayload = {
  data: LoginResponse
}

export type RemovePaymentMethodInput = {
  paymentMethodId: Scalars["String"]["input"]
}

export type RemovePaymentMethodPayload = {
  status: Scalars["String"]["output"]
}

export type ReportPartnerAskedCashInput = {
  details?: InputMaybe<Scalars["String"]["input"]>
  reservationId: Scalars["String"]["input"]
}

export type ReportPartnerAskedCashPayload = {
  status: Maybe<Scalars["String"]["output"]>
}

export type RequestAppLinkTextInput = {
  phone: Scalars["String"]["input"]
  token?: InputMaybe<Scalars["String"]["input"]>
}

export type RequestAppLinkTextPayload = {
  status: Scalars["String"]["output"]
}

export type ResendConfirmationEmailInput = {
  email?: InputMaybe<Scalars["String"]["input"]>
  reservationId: Scalars["String"]["input"]
}

export type ResendConfirmationEmailPayload = {
  status: Maybe<Scalars["String"]["output"]>
}

/** A reservation at a storage storage_spot */
export type Reservation = {
  amount: Maybe<Scalars["Int"]["output"]>
  appName: Maybe<Scalars["String"]["output"]>
  bagPictures: Array<File>
  bags: Scalars["Int"]["output"]
  cancellationReason: Maybe<Scalars["String"]["output"]>
  changedAt: Maybe<Scalars["DateTime"]["output"]>
  checkInDate: Scalars["DateTime"]["output"]
  checkInMethod: Maybe<ReservationCheckInMethod>
  checkInSource: Maybe<ReservationCheckInSource>
  checkOutDate: Scalars["DateTime"]["output"]
  checkedBags: Maybe<Scalars["Int"]["output"]>
  checkedInAt: Maybe<Scalars["DateTime"]["output"]>
  checkedOutAt: Maybe<Scalars["DateTime"]["output"]>
  checkedProducts: Maybe<Array<ReservationProduct>>
  confirmationEmailSentTo: Maybe<Scalars["String"]["output"]>
  currency: Maybe<Scalars["String"]["output"]>
  discount: Maybe<Scalars["Int"]["output"]>
  failureReason: Maybe<Scalars["String"]["output"]>
  /** @deprecated 16/10/2023 */
  flexibleTimeFee: Maybe<Scalars["Int"]["output"]>
  id: Scalars["String"]["output"]
  insertedAt: Scalars["DateTime"]["output"]
  isFirstReservation: Maybe<Scalars["Boolean"]["output"]>
  /** @deprecated 14/02/2024 */
  isWalkIn: Maybe<Scalars["Boolean"]["output"]>
  /** @deprecated 31/08/2023 */
  locker: Maybe<Locker>
  /** @deprecated 18/07/2024 */
  lockerCodes: Maybe<Array<Maybe<LockerCode>>>
  /** @deprecated 31/08/2023 */
  lockerId: Maybe<Scalars["String"]["output"]>
  lockers: Maybe<Array<Maybe<Lockers>>>
  nonBillableDays: Maybe<Scalars["Int"]["output"]>
  numberOfDays: Maybe<Scalars["Int"]["output"]>
  payments: Maybe<Array<Payment>>
  pin: Maybe<Scalars["String"]["output"]>
  pricePerBag: Maybe<Scalars["Int"]["output"]>
  products: Maybe<Array<ReservationProduct>>
  reservationCode: Scalars["String"]["output"]
  reservationSource: Maybe<ReservationSource>
  review: Maybe<Review>
  /** @deprecated 06/03/2023 */
  reviews: Maybe<Array<Review>>
  serviceFee: Maybe<Scalars["Int"]["output"]>
  services: Maybe<Array<StorageService>>
  status: ReservationStatus
  store: StorageSpot
  storeId: Scalars["String"]["output"]
  subTotal: Maybe<Scalars["Int"]["output"]>
  user: User
  userFeedback: Maybe<Scalars["String"]["output"]>
  userId: Scalars["String"]["output"]
}

export enum ReservationCheckInMethod {
  Auto = "AUTO",
  LockerApp = "LOCKER_APP",
  LockerCustomer = "LOCKER_CUSTOMER",
  Manual = "MANUAL",
  ManualCustomer = "MANUAL_CUSTOMER",
}

export enum ReservationCheckInSource {
  Admin = "ADMIN",
  Auto = "AUTO",
  Customer = "CUSTOMER",
  Partner = "PARTNER",
}

export type ReservationConnection = {
  edges: Array<ReservationEdge>
  pageInfo: PageInfo
}

export type ReservationEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Reservation
}

export type ReservationInput = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate: Scalars["NaiveDateTime"]["input"]
  checkOutDate: Scalars["NaiveDateTime"]["input"]
  couponCode?: InputMaybe<Scalars["String"]["input"]>
  products?: InputMaybe<Array<LuggageProductInput>>
  referralCode?: InputMaybe<Scalars["String"]["input"]>
  services?: InputMaybe<Array<StorageServiceInput>>
  storeId?: InputMaybe<Scalars["String"]["input"]>
  userId?: InputMaybe<Scalars["String"]["input"]>
}

export enum ReservationPaymentReceiver {
  Bounce = "BOUNCE",
  Store = "STORE",
}

export type ReservationProduct = {
  dimensions: Maybe<Scalars["String"]["output"]>
  price: Scalars["Int"]["output"]
  quantity: Scalars["Int"]["output"]
  serviceFee: Scalars["Int"]["output"]
  subTotal: Scalars["Int"]["output"]
  type: ProductType
}

export enum ReservationSource {
  Online = "ONLINE",
  PartnerAcquired = "PARTNER_ACQUIRED",
  WalkIn = "WALK_IN",
}

export enum ReservationStatus {
  CancellationRequested = "CANCELLATION_REQUESTED",
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
  Failed = "FAILED",
  NoShow = "NO_SHOW",
  Processing = "PROCESSING",
  TimedOut = "TIMED_OUT",
}

export type Review = {
  active: Maybe<Scalars["Boolean"]["output"]>
  completedAt: Maybe<Scalars["DateTime"]["output"]>
  feedback: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  rating: Maybe<Scalars["Int"]["output"]>
  reasons: Maybe<Array<ReviewReason>>
  store: StorageSpot
  storeId: Scalars["String"]["output"]
  translatedFeedback: Maybe<Scalars["String"]["output"]>
  user: ReviewUser
  userId: Scalars["String"]["output"]
}

export type ReviewConnection = {
  edges: Array<ReviewEdge>
  pageInfo: PageInfo
}

export type ReviewEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Review
}

export enum ReviewOrderByField {
  CompletedAt = "COMPLETED_AT",
  PreferredLanguage = "PREFERRED_LANGUAGE",
  Rating = "RATING",
  Relevance = "RELEVANCE",
  UserId = "USER_ID",
}

export enum ReviewReason {
  AdditionalSecurity = "ADDITIONAL_SECURITY",
  ChargedExtraAtStore = "CHARGED_EXTRA_AT_STORE",
  ConvenientLocation = "CONVENIENT_LOCATION",
  EasyCico = "EASY_CICO",
  FastService = "FAST_SERVICE",
  FoundAnotherService = "FOUND_ANOTHER_SERVICE",
  FriendlyStaff = "FRIENDLY_STAFF",
  HardCico = "HARD_CICO",
  HardToFindStore = "HARD_TO_FIND_STORE",
  InconvenientLocation = "INCONVENIENT_LOCATION",
  OpenLate = "OPEN_LATE",
  Other = "OTHER",
  OverchargedDueToCico = "OVERCHARGED_DUE_TO_CICO",
  PaidWithCashInstead = "PAID_WITH_CASH_INSTEAD",
  PartnerDidNotKnowBounce = "PARTNER_DID_NOT_KNOW_BOUNCE",
  PartnerWasNotPaid = "PARTNER_WAS_NOT_PAID",
  PoorStaffService = "POOR_STAFF_SERVICE",
  SafetyConcerns = "SAFETY_CONCERNS",
  StoreClosed = "STORE_CLOSED",
  TechnicalIssues = "TECHNICAL_ISSUES",
  TurnedAwayByStore = "TURNED_AWAY_BY_STORE",
  UnprofessionalProcess = "UNPROFESSIONAL_PROCESS",
  WrongExpectationsDedicatedBusiness = "WRONG_EXPECTATIONS_DEDICATED_BUSINESS",
  WrongExpectationsLockers = "WRONG_EXPECTATIONS_LOCKERS",
}

export type ReviewSortOrder = {
  direction: SortOrderDirection
  field: ReviewOrderByField
}

export type ReviewUser = {
  firstName: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  lastName: Maybe<Scalars["String"]["output"]>
  profilePictureUrl: Maybe<Scalars["String"]["output"]>
}

export type RootMutationType = {
  authenticateWithEmail: Maybe<AuthenticateWithEmailPayload>
  authenticateWithPhone: Maybe<AuthenticateWithPhonePayload>
  authenticateWithSocial: Maybe<AuthenticateWithSocialPayload>
  cancelReservation: Maybe<CancelReservationPayload>
  checkInReservation: Maybe<CheckInReservationPayload>
  checkOutReservation: Maybe<CheckOutReservationPayload>
  completePhoneRegistration: Maybe<CompletePhoneRegistrationPayload>
  completeReview: Maybe<CompleteReviewPayload>
  createReservationConversation: Maybe<CreateReservationConversationPayload>
  deleteBagPicture: Maybe<DeleteBagPicturePayload>
  deleteUser: Maybe<DeleteUserPayload>
  editReservation: Maybe<EditReservationPayload>
  generatePaymentMethodSetupIntent: Maybe<GeneratePaymentMethodSetupIntentPayload>
  linkCouponToUser: Maybe<LinkCouponToUserPayload>
  lockerDropOff: Maybe<LockerDropOffPayload>
  lockerPickup: Maybe<LockerPickupPayload>
  moveReservationToAnotherStore: Maybe<MoveReservationToAnotherStorePayload>
  placeGuestReservationIntent: Maybe<PlaceGuestReservationIntentPayload>
  placeReservationIntent: Maybe<PlaceReservationIntentPayload>
  redeemCoupon: Maybe<RedeemCouponPayload>
  refreshToken: Maybe<RefreshTokenPayload>
  registerDeviceToken: Maybe<RegisterDeviceTokenPayload>
  registerUser: Maybe<RegisterUserPayload>
  removePaymentMethod: Maybe<RemovePaymentMethodPayload>
  reportPartnerAskedCash: Maybe<ReportPartnerAskedCashPayload>
  requestAppLinkText: Maybe<RequestAppLinkTextPayload>
  resendConfirmationEmail: Maybe<ResendConfirmationEmailPayload>
  saveBagPicture: Maybe<SaveBagPicturePayload>
  saveProofPicture: Maybe<SaveProofPicturePayload>
  setupPaypal: Maybe<SetupPaypalPayload>
  startReservationChat: Maybe<StartReservationChatPayload>
  subscribeNewsletter: Maybe<SubscribeNewsletterPayload>
  tipReservation: Maybe<TipReservationPayload>
  unsubscribeNewsletter: Maybe<UnsubscribeNewsletterPayload>
  updateUser: Maybe<UpdateUserPayload>
  verifyEmailCode: Maybe<VerifyEmailCodePayload>
  verifyPhoneCode: Maybe<VerifyPhoneCodePayload>
}

export type RootMutationTypeAuthenticateWithEmailArgs = {
  input: AuthenticateWithEmailInput
}

export type RootMutationTypeAuthenticateWithPhoneArgs = {
  input: AuthenticateWithPhoneInput
}

export type RootMutationTypeAuthenticateWithSocialArgs = {
  input: AuthenticateWithSocialInput
}

export type RootMutationTypeCancelReservationArgs = {
  input: CancelReservationInput
}

export type RootMutationTypeCheckInReservationArgs = {
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeCheckOutReservationArgs = {
  forceTimelyCheckout?: InputMaybe<Scalars["Boolean"]["input"]>
  id: Scalars["ID"]["input"]
}

export type RootMutationTypeCompletePhoneRegistrationArgs = {
  input: CompletePhoneRegistrationInput
}

export type RootMutationTypeCompleteReviewArgs = {
  input: CompleteReviewInput
}

export type RootMutationTypeCreateReservationConversationArgs = {
  input: CreateReservationConversationInput
}

export type RootMutationTypeDeleteBagPictureArgs = {
  input: DeleteBagPictureInput
}

export type RootMutationTypeEditReservationArgs = {
  input: EditReservationInput
}

export type RootMutationTypeLinkCouponToUserArgs = {
  input: LinkCouponToUserInput
}

export type RootMutationTypeLockerDropOffArgs = {
  input: LockerDropOffInput
}

export type RootMutationTypeLockerPickupArgs = {
  input: LockerPickupInput
}

export type RootMutationTypeMoveReservationToAnotherStoreArgs = {
  input: MoveReservationToAnotherStoreInput
}

export type RootMutationTypePlaceGuestReservationIntentArgs = {
  input: PlaceGuestReservationIntentInput
}

export type RootMutationTypePlaceReservationIntentArgs = {
  input: PlaceReservationIntentInput
}

export type RootMutationTypeRedeemCouponArgs = {
  input: RedeemCouponInput
}

export type RootMutationTypeRefreshTokenArgs = {
  input: RefreshTokenInput
}

export type RootMutationTypeRegisterDeviceTokenArgs = {
  input: RegisterDeviceTokenInput
}

export type RootMutationTypeRegisterUserArgs = {
  input: RegisterUserInput
}

export type RootMutationTypeRemovePaymentMethodArgs = {
  input: RemovePaymentMethodInput
}

export type RootMutationTypeReportPartnerAskedCashArgs = {
  input: ReportPartnerAskedCashInput
}

export type RootMutationTypeRequestAppLinkTextArgs = {
  input: RequestAppLinkTextInput
}

export type RootMutationTypeResendConfirmationEmailArgs = {
  input: ResendConfirmationEmailInput
}

export type RootMutationTypeSaveBagPictureArgs = {
  input: SaveBagPictureInput
}

export type RootMutationTypeSaveProofPictureArgs = {
  input: SaveProofPictureInput
}

export type RootMutationTypeSetupPaypalArgs = {
  input: SetupPaypalInput
}

export type RootMutationTypeStartReservationChatArgs = {
  input: StartReservationChatInput
}

export type RootMutationTypeSubscribeNewsletterArgs = {
  input: SubscribeNewsletterInput
}

export type RootMutationTypeTipReservationArgs = {
  input: TipReservationInput
}

export type RootMutationTypeUnsubscribeNewsletterArgs = {
  input: UnsubscribeNewsletterInput
}

export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput
}

export type RootMutationTypeVerifyEmailCodeArgs = {
  input: VerifyEmailCodeInput
}

export type RootMutationTypeVerifyPhoneCodeArgs = {
  input: VerifyPhoneCodeInput
}

export type RootQueryType = {
  aggregateRating: AggregateRating
  cities: CityConnection
  city: City
  countries: Array<Country>
  dynamicLink: Scalars["String"]["output"]
  generateReconciledStoragePriceQuote: ReconciledStoragePriceQuote
  generateStoragePriceQuote: StoragePriceQuote
  generateStripeTerminalConnectionToken: Maybe<Scalars["String"]["output"]>
  getLockerAvailability: Maybe<Array<Maybe<LockersAvailability>>>
  healthCheck: Scalars["Boolean"]["output"]
  isUserRegistered: Scalars["Boolean"]["output"]
  luggageStorageCommissions: LuggageStorageCommissions
  microsites: MicrositeConnection
  poi: Poi
  pois: PoiConnection
  refundBreakdown: RefundBreakdown
  reservation: Reservation
  reservations: Array<Reservation>
  review: Review
  reviews: ReviewConnection
  services: ServiceConnection
  store: StorageSpot
  stores: StorageSpotConnection
  supportConversations: Maybe<Array<Maybe<SupportConversation>>>
  tags: TagConnection
  user: User
  walkInStore: StorageSpot
}

export type RootQueryTypeAggregateRatingArgs = {
  cityId?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeCitiesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  coordinates?: InputMaybe<CoordinatesInput>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  onlyActive?: Scalars["Boolean"]["input"]
  orderBy?: InputMaybe<Array<InputMaybe<CitySortOrder>>>
}

export type RootQueryTypeCityArgs = {
  coordinates?: InputMaybe<CoordinatesInput>
  id?: InputMaybe<Scalars["String"]["input"]>
  onlyActive?: Scalars["Boolean"]["input"]
  slug?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeDynamicLinkArgs = {
  extraParams?: InputMaybe<Scalars["Json"]["input"]>
  url: Scalars["String"]["input"]
  withAccessToken?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type RootQueryTypeGenerateReconciledStoragePriceQuoteArgs = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate?: InputMaybe<Scalars["DateTime"]["input"]>
  checkInDateNaive?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  checkOutDate?: InputMaybe<Scalars["DateTime"]["input"]>
  checkOutDateNaive?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  products?: InputMaybe<Array<LuggageProductInput>>
  reservationId: Scalars["String"]["input"]
  services?: InputMaybe<Array<StorageServiceInput>>
  useCredits?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type RootQueryTypeGenerateStoragePriceQuoteArgs = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate?: InputMaybe<Scalars["DateTime"]["input"]>
  checkInDateNaive?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  checkOutDate?: InputMaybe<Scalars["DateTime"]["input"]>
  checkOutDateNaive?: InputMaybe<Scalars["NaiveDateTime"]["input"]>
  couponCode?: InputMaybe<Scalars["String"]["input"]>
  hasFlexibleTime?: InputMaybe<Scalars["Boolean"]["input"]>
  paymentReceiver?: InputMaybe<ReservationPaymentReceiver>
  products?: InputMaybe<Array<LuggageProductInput>>
  referralCode?: InputMaybe<Scalars["String"]["input"]>
  reservationSource?: InputMaybe<ReservationSource>
  services?: InputMaybe<Array<StorageServiceInput>>
  storeId?: InputMaybe<Scalars["String"]["input"]>
  useCredits?: InputMaybe<Scalars["Boolean"]["input"]>
  waiveServiceFee?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type RootQueryTypeGetLockerAvailabilityArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeIsUserRegisteredArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>
  phone?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeLuggageStorageCommissionsArgs = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeMicrositesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type RootQueryTypePoiArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>
  onlyActive?: Scalars["Boolean"]["input"]
  slug?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypePoisArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  coordinates?: InputMaybe<CoordinatesInput>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  onlyActive?: Scalars["Boolean"]["input"]
}

export type RootQueryTypeRefundBreakdownArgs = {
  id: Scalars["String"]["input"]
  preferredPolicy?: InputMaybe<RefundPolicy>
  reason: RefundReason
}

export type RootQueryTypeReservationArgs = {
  id: Scalars["String"]["input"]
}

export type RootQueryTypeReservationsArgs = {
  reservationIds?: InputMaybe<Array<Scalars["String"]["input"]>>
}

export type RootQueryTypeReviewArgs = {
  id: Scalars["String"]["input"]
}

export type RootQueryTypeReviewsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  cityId?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  minRating?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Array<InputMaybe<ReviewSortOrder>>>
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>
  storeId?: InputMaybe<Scalars["String"]["input"]>
  withFeedback?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type RootQueryTypeServicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type RootQueryTypeStoreArgs = {
  coordinates?: InputMaybe<CoordinatesInput>
  filter?: InputMaybe<StoreFilterInput>
  id?: InputMaybe<Scalars["String"]["input"]>
  language?: InputMaybe<Scalars["String"]["input"]>
  slug?: InputMaybe<Scalars["String"]["input"]>
}

export type RootQueryTypeStoresArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  boundingBox?: InputMaybe<BoundingBoxInput>
  cityId?: InputMaybe<Scalars["String"]["input"]>
  coordinates?: InputMaybe<CoordinatesInput>
  distance?: InputMaybe<Scalars["Float"]["input"]>
  filter?: InputMaybe<StoreFilterInput>
  first?: InputMaybe<Scalars["Int"]["input"]>
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  language?: InputMaybe<Scalars["String"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  minRating?: InputMaybe<Scalars["Float"]["input"]>
}

export type RootQueryTypeTagsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

export type RootQueryTypeWalkInStoreArgs = {
  filter?: InputMaybe<StoreFilterInput>
  id?: InputMaybe<Scalars["String"]["input"]>
  language?: InputMaybe<Scalars["String"]["input"]>
  referralCode?: InputMaybe<Scalars["String"]["input"]>
  reservationSource?: InputMaybe<ReservationSource>
}

export type SaveBagPictureInput = {
  externalId: Scalars["String"]["input"]
  reservationId: Scalars["String"]["input"]
}

export type SaveBagPicturePayload = {
  status: Scalars["String"]["output"]
}

export type SaveProofPictureInput = {
  externalId: Scalars["String"]["input"]
  reason: Scalars["String"]["input"]
  reservationId: Scalars["String"]["input"]
}

export type SaveProofPicturePayload = {
  status: Scalars["String"]["output"]
}

export type Service = {
  key: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type ServiceConnection = {
  edges: Array<ServiceEdge>
  pageInfo: PageInfo
}

export type ServiceEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Service
}

export type SetupPaypalInput = {
  paypalPaymentMethodNonce: Scalars["String"]["input"]
}

export type SetupPaypalPayload = {
  status: Scalars["String"]["output"]
}

export enum SortOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type StartReservationChatInput = {
  reservationId: Scalars["String"]["input"]
}

export type StartReservationChatPayload = {
  channelUrl: Scalars["String"]["output"]
}

export type StoragePriceQuote = {
  bags: Maybe<Scalars["Int"]["output"]>
  couponCode: Maybe<Scalars["String"]["output"]>
  couponStatus: Maybe<CouponStatus>
  currency: Scalars["String"]["output"]
  discount: Maybe<Scalars["Int"]["output"]>
  /** @deprecated 16/10/2023 */
  flexibleTimeFee: Maybe<Scalars["Int"]["output"]>
  modifiers: Maybe<Array<Scalars["String"]["output"]>>
  nonBillableDays: Maybe<Scalars["Int"]["output"]>
  numberOfDays: Maybe<Scalars["Int"]["output"]>
  paymentBreakdown: Array<StoragePriceQuotePaymentBreakdown>
  paymentReceiver: Maybe<ReservationPaymentReceiver>
  pricePerBag: Maybe<Scalars["Int"]["output"]>
  products: Maybe<Array<LuggageProduct>>
  reservationSource: Maybe<ReservationSource>
  serviceFee: Maybe<Scalars["Int"]["output"]>
  serviceFeePerBag: Maybe<Scalars["Int"]["output"]>
  serviceFeePerBagNotWaived: Maybe<Scalars["Int"]["output"]>
  serviceFees: Maybe<Array<StorageService>>
  subTotal: Maybe<Scalars["Int"]["output"]>
  total: Scalars["Int"]["output"]
}

export type StoragePriceQuotePaymentBreakdown = {
  amount: Scalars["Int"]["output"]
  source: StoragePriceQuotePaymentSource
}

export enum StoragePriceQuotePaymentSource {
  Card = "CARD",
  Credits = "CREDITS",
  Store = "STORE",
}

export type StorageService = {
  /** Not every service has a price per bag */
  pricePerBag: Maybe<Scalars["Int"]["output"]>
  /** What a service *would* cost if enabled. */
  priceWhenEnabled: Scalars["Int"]["output"]
  /** The actual amount the customer would be charged */
  quotedPrice: Scalars["Int"]["output"]
  type: Scalars["String"]["output"]
}

export type StorageServiceInput = {
  enabled: Scalars["Boolean"]["input"]
  type: Scalars["String"]["input"]
}

export type StorageSpot = {
  acceptsDirectPayments: Maybe<Scalars["Boolean"]["output"]>
  address: Address
  chatEnabled: Scalars["Boolean"]["output"]
  checkInMethod: StoreCheckInMethod
  city: City
  cityId: Scalars["String"]["output"]
  coordinates: Coordinates
  customerCicoEnabled: Maybe<Scalars["Boolean"]["output"]>
  /** @deprecated 21/03/2023 */
  description: Maybe<Scalars["String"]["output"]>
  directions: Maybe<Scalars["String"]["output"]>
  displayStatus: DisplayStatus
  distance: Maybe<Scalars["Float"]["output"]>
  emails: Maybe<Array<Scalars["String"]["output"]>>
  excludedReservationSources: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  gmbAddress: Maybe<GmbAddress>
  gmbExternalId: Maybe<Scalars["String"]["output"]>
  gmbLocationName: Scalars["String"]["output"]
  gmbLocationPlaceId: Maybe<Scalars["String"]["output"]>
  gmbPhone: Maybe<Scalars["String"]["output"]>
  hasSignage: Scalars["Boolean"]["output"]
  id: Scalars["String"]["output"]
  /** @deprecated 06/02/2024 */
  images: Maybe<Array<Scalars["String"]["output"]>>
  kioskImage: Maybe<File>
  locale: Maybe<Scalars["String"]["output"]>
  lowestPrice: Scalars["Int"]["output"]
  /** @deprecated 09/08/2022 */
  luggageStorageTips: Scalars["Boolean"]["output"]
  marketingPageEnabled: Scalars["Boolean"]["output"]
  maxCapacity: Scalars["Int"]["output"]
  maxDays: Maybe<Scalars["Int"]["output"]>
  name: Scalars["String"]["output"]
  nickname: Scalars["String"]["output"]
  openingHourExceptions: Maybe<Array<OpeningHourException>>
  openingHours: Maybe<Array<OpeningHour>>
  partnershipInfo: Maybe<PartnershipInfo>
  phones: Maybe<Array<Scalars["String"]["output"]>>
  pictures: Array<File>
  /** @deprecated 14/06/23 */
  pois: Maybe<Array<Poi>>
  price: Scalars["Int"]["output"]
  /** @deprecated 04/06/2024 */
  pricing: Pricing
  products: Maybe<Array<LuggageProduct>>
  rating: Maybe<Scalars["Float"]["output"]>
  reviewCount: Scalars["Int"]["output"]
  reviews: ReviewConnection
  services: Maybe<Array<Service>>
  slug: Scalars["String"]["output"]
  tags: Maybe<Array<Tag>>
  thumbnail: Maybe<File>
  tips: Maybe<Scalars["Boolean"]["output"]>
  type: Scalars["String"]["output"]
  visibilityBoost: Scalars["Boolean"]["output"]
}

export type StorageSpotPoisArgs = {
  distance?: InputMaybe<Scalars["Int"]["input"]>
  limit?: InputMaybe<Scalars["Int"]["input"]>
}

export type StorageSpotReviewsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  minRating?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Array<InputMaybe<ReviewSortOrder>>>
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>
  withFeedback?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type StorageSpotConnection = {
  edges: Array<StorageSpotEdge>
  pageInfo: PageInfo
}

export type StorageSpotEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: StorageSpot
}

export enum StoreCheckInMethod {
  Auto = "AUTO",
  LockerApp = "LOCKER_APP",
  LockerCustomer = "LOCKER_CUSTOMER",
  Manual = "MANUAL",
  ManualCustomer = "MANUAL_CUSTOMER",
}

export type StoreCommission = {
  expiresAt: Maybe<Scalars["Date"]["output"]>
  type: Maybe<CommissionType>
  value: Maybe<Scalars["Float"]["output"]>
}

export type StoreFilterInput = {
  bags?: InputMaybe<Scalars["Int"]["input"]>
  checkInDate: Scalars["NaiveDateTime"]["input"]
  checkOutDate: Scalars["NaiveDateTime"]["input"]
  excludeUnavailable?: InputMaybe<Scalars["Boolean"]["input"]>
  includeLockers?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type SubscribeNewsletterInput = {
  email: Scalars["String"]["input"]
}

export type SubscribeNewsletterPayload = {
  status: Scalars["String"]["output"]
}

export type SupportConversation = {
  id: Maybe<Scalars["String"]["output"]>
  reason: Maybe<Scalars["String"]["output"]>
  reservationId: Maybe<Scalars["String"]["output"]>
}

export type Tag = {
  colorHex: Scalars["String"]["output"]
  key: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
  weight: Scalars["Int"]["output"]
}

export type TagConnection = {
  edges: Array<TagEdge>
  pageInfo: PageInfo
}

export type TagEdge = {
  cursor: Maybe<Scalars["String"]["output"]>
  node: Tag
}

export type TipReservationInput = {
  amount: Scalars["Int"]["input"]
  reservationId: Scalars["String"]["input"]
  token?: InputMaybe<Scalars["String"]["input"]>
}

export type TipReservationPayload = {
  status: Scalars["String"]["output"]
}

export type Translation = {
  language: Scalars["String"]["output"]
  text: Scalars["String"]["output"]
}

export type Translations = {
  slug: Maybe<Array<Maybe<Translation>>>
}

export type UnsubscribeNewsletterInput = {
  email?: InputMaybe<Scalars["String"]["input"]>
}

export type UnsubscribeNewsletterPayload = {
  status: Scalars["String"]["output"]
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars["String"]["input"]>
  firstName?: InputMaybe<Scalars["String"]["input"]>
  lastName?: InputMaybe<Scalars["String"]["input"]>
  locale?: InputMaybe<Scalars["String"]["input"]>
  nonce?: InputMaybe<Scalars["String"]["input"]>
  phone?: InputMaybe<Scalars["String"]["input"]>
  reservationId?: InputMaybe<Scalars["String"]["input"]>
}

export type UpdateUserPayload = {
  data: User
}

/** the current user */
export type User = {
  billingInformation: BillingInformation
  creditsBalances: Maybe<Array<CreditsBalance>>
  creditsTransactions: Array<CreditsTransaction>
  email: Maybe<Scalars["String"]["output"]>
  emailVerified: Scalars["Boolean"]["output"]
  firstActiveReservation: Maybe<Reservation>
  firstName: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  incompleteReviews: ReviewConnection
  lastName: Maybe<Scalars["String"]["output"]>
  locale: Maybe<Scalars["String"]["output"]>
  notifications: Maybe<Notifications>
  phone: Maybe<Scalars["String"]["output"]>
  phoneVerified: Scalars["Boolean"]["output"]
  profilePictureUrl: Maybe<Scalars["String"]["output"]>
  referralCouponCode: Maybe<Scalars["String"]["output"]>
  registrationCompleted: Maybe<Scalars["Boolean"]["output"]>
  reservations: ReservationConnection
  userCoupons: Maybe<Array<UserCoupon>>
}

/** the current user */
export type UserCreditsTransactionsArgs = {
  referrals?: InputMaybe<Scalars["Boolean"]["input"]>
}

/** the current user */
export type UserFirstActiveReservationArgs = {
  storeId?: InputMaybe<Scalars["String"]["input"]>
}

/** the current user */
export type UserIncompleteReviewsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
}

/** the current user */
export type UserReservationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>
  before?: InputMaybe<Scalars["String"]["input"]>
  checkInDateGt?: InputMaybe<Scalars["DateTime"]["input"]>
  checkInDateLt?: InputMaybe<Scalars["DateTime"]["input"]>
  checkOutDateGt?: InputMaybe<Scalars["DateTime"]["input"]>
  checkOutDateLt?: InputMaybe<Scalars["DateTime"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  storeId?: InputMaybe<Scalars["String"]["input"]>
}

export type UserCoupon = {
  active: Scalars["Boolean"]["output"]
  coupon: Coupon
  expiryDate: Maybe<Scalars["Date"]["output"]>
  remainingRedemptions: Maybe<Scalars["Int"]["output"]>
}

export type UserInput = {
  email: Scalars["String"]["input"]
  firstName: Scalars["String"]["input"]
  lastName: Scalars["String"]["input"]
  locale?: InputMaybe<Scalars["String"]["input"]>
  phone?: InputMaybe<Scalars["String"]["input"]>
}

export type VerifyEmailCodeInput = {
  email: Scalars["String"]["input"]
  /** sends the OTP code by SMS instead */
  sendPhoneOtp?: InputMaybe<Scalars["Boolean"]["input"]>
  verificationCode: Scalars["String"]["input"]
}

export type VerifyEmailCodePayload = {
  data: LoginResponse
}

export type VerifyPhoneCodeInput = {
  phone: Scalars["String"]["input"]
  verificationCode: Scalars["String"]["input"]
}

export type VerifyPhoneCodePayload = {
  data: LoginResponse
}

export type WalkInBonus = {
  amount: Maybe<Scalars["Int"]["output"]>
  currency: Maybe<Scalars["String"]["output"]>
  perNumBookings: Maybe<Scalars["Int"]["output"]>
}

export type CityTranslationsFragment = {
  translations: { slug: Array<{ language: string; text: string } | null> | null }
}

export type CityFragment = {
  id: string
  slug: string
  name: string
  active: boolean | null
  priority: number
  paFeatured: boolean
  featured: boolean
  query: string | null
  country: string
  region: Region | null
  luggagePrice: number | null
  lowestPrice: number | null
  prismicId: string | null
  currency: string
  marketingLanguages: Array<string | null>
  storeCount?: number
  labels: Array<{ name: string; metadata: any | null }>
  coordinates: { latitude: number; longitude: number } | null
  nearestStore?: { distance: number | null } | null
  pois?: Array<{
    id: string
    slug: string
    name: string
    query: string | null
    priority: number
    active: boolean
    marketingLanguages: Array<string>
    coordinates: { latitude: number; longitude: number }
    translations: { slug: Array<{ language: string; text: string } | null> | null }
  }> | null
}

export type GetCityQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>
  coordinates?: InputMaybe<CoordinatesInput>
  withCount?: Scalars["Boolean"]["input"]
  withPois?: Scalars["Boolean"]["input"]
  withNearest?: Scalars["Boolean"]["input"]
  onlyActive?: Scalars["Boolean"]["input"]
}>

export type GetCityQuery = {
  city: {
    boilerplateContent: any | null
    name: string
    state: string | null
    country: string
    id: string
    slug: string
    active: boolean | null
    priority: number
    paFeatured: boolean
    featured: boolean
    query: string | null
    region: Region | null
    luggagePrice: number | null
    lowestPrice: number | null
    prismicId: string | null
    currency: string
    marketingLanguages: Array<string | null>
    storeCount?: number
    mainStore: {
      id: string
      nickname: string
      gmbPhone: string | null
      gmbLocationName: string
      gmbAddress: {
        addressLines: string
        locality: string | null
        administrativeArea: string | null
        regionCode: string
        postalCode: string | null
      } | null
      coordinates: { latitude: number; longitude: number }
      city: { name: string; state: string | null; country: string }
      address: { street: string; zipCode: string }
      openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
      openingHourExceptions: Array<{
        closeTime: string | null
        closed: boolean
        startDate: string
        endDate: string
        openTime: string | null
        reason: string | null
      }> | null
    } | null
    labels: Array<{ name: string; metadata: any | null }>
    coordinates: { latitude: number; longitude: number } | null
    nearestStore?: { distance: number | null } | null
    pois?: Array<{
      id: string
      slug: string
      name: string
      query: string | null
      priority: number
      active: boolean
      marketingLanguages: Array<string>
      coordinates: { latitude: number; longitude: number }
      translations: { slug: Array<{ language: string; text: string } | null> | null }
    }> | null
    translations: { slug: Array<{ language: string; text: string } | null> | null }
  }
}

export type GetCitiesQueryVariables = Exact<{
  before?: InputMaybe<Scalars["String"]["input"]>
  first?: InputMaybe<Scalars["Int"]["input"]>
  last?: InputMaybe<Scalars["Int"]["input"]>
  after?: InputMaybe<Scalars["String"]["input"]>
  orderBy?: InputMaybe<Array<InputMaybe<CitySortOrder>> | InputMaybe<CitySortOrder>>
  withCount?: Scalars["Boolean"]["input"]
  withPois?: Scalars["Boolean"]["input"]
  withNearest?: Scalars["Boolean"]["input"]
  onlyActive?: Scalars["Boolean"]["input"]
}>

export type GetCitiesQuery = {
  cities: {
    edges: Array<{
      node: {
        id: string
        slug: string
        name: string
        active: boolean | null
        priority: number
        paFeatured: boolean
        featured: boolean
        query: string | null
        country: string
        region: Region | null
        luggagePrice: number | null
        lowestPrice: number | null
        prismicId: string | null
        currency: string
        marketingLanguages: Array<string | null>
        storeCount?: number
        labels: Array<{ name: string; metadata: any | null }>
        coordinates: { latitude: number; longitude: number } | null
        nearestStore?: { distance: number | null } | null
        pois?: Array<{
          id: string
          slug: string
          name: string
          query: string | null
          priority: number
          active: boolean
          marketingLanguages: Array<string>
          coordinates: { latitude: number; longitude: number }
          translations: { slug: Array<{ language: string; text: string } | null> | null }
        }> | null
        translations: { slug: Array<{ language: string; text: string } | null> | null }
      }
    }>
    pageInfo: { hasNextPage: boolean; endCursor: string | null }
  }
}

export type GetNearbyCitiesQueryVariables = Exact<{
  latitude: Scalars["Float"]["input"]
  longitude: Scalars["Float"]["input"]
  first?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetNearbyCitiesQuery = {
  cities: {
    edges: Array<{
      node: {
        id: string
        slug: string
        name: string
        translations: { slug: Array<{ language: string; text: string } | null> | null }
      }
    }>
  }
}

export type GetLuggageStorageSitemapCitiesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
  after?: InputMaybe<Scalars["String"]["input"]>
}>

export type GetLuggageStorageSitemapCitiesQuery = {
  cities: {
    edges: Array<{
      node: {
        id: string
        slug: string
        name: string
        priority: number
        marketingLanguages: Array<string | null>
        pois: Array<{
          id: string
          slug: string
          name: string
          priority: number
          active: boolean
          marketingLanguages: Array<string>
          translations: { slug: Array<{ language: string; text: string } | null> | null }
        }> | null
        translations: { slug: Array<{ language: string; text: string } | null> | null }
      }
    }>
    pageInfo: { hasNextPage: boolean; endCursor: string | null }
  }
}

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>

export type GetCountriesQuery = {
  countries: Array<{ country: string; luggagePrice: number; lowestPrice: number; currency: string }>
}

export type LoginResponseFragment = { accessToken: string; refreshToken: string | null; expiry: number }

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type RegisterUserMutation = {
  registerUser: { data: { accessToken: string; refreshToken: string | null; expiry: number } } | null
}

export type SubscribeNewsletterMutationVariables = Exact<{
  input: SubscribeNewsletterInput
}>

export type SubscribeNewsletterMutation = { subscribeNewsletter: { status: string } | null }

export type LuggageStorageCommissionsQueryVariables = Exact<{
  countryCode: Scalars["String"]["input"]
}>

export type LuggageStorageCommissionsQuery = {
  luggageStorageCommissions: {
    currency: string | null
    nonExclusive: { walkInBonus: { perNumBookings: number | null; amount: number | null } | null } | null
    exclusive: { walkInBonus: { perNumBookings: number | null; amount: number | null } | null } | null
  }
}

export type PoiTranslationsFragment = {
  translations: { slug: Array<{ language: string; text: string } | null> | null }
}

export type PoiFragment = {
  id: string
  name: string
  slug: string
  query: string | null
  priority: number
  type: PoiType
  active: boolean
  marketingLanguages: Array<string>
  boilerplateContent: any | null
  coordinates: { latitude: number; longitude: number }
  city: { id: string; slug: string }
  mainStore: {
    id: string
    nickname: string
    gmbPhone: string | null
    gmbLocationName: string
    gmbAddress: {
      addressLines: string
      locality: string | null
      administrativeArea: string | null
      regionCode: string
      postalCode: string | null
    } | null
    coordinates: { latitude: number; longitude: number }
    city: { name: string; state: string | null; country: string }
    address: { street: string; zipCode: string }
    openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
    openingHourExceptions: Array<{
      closeTime: string | null
      closed: boolean
      startDate: string
      endDate: string
      openTime: string | null
      reason: string | null
    }> | null
  } | null
  labels: Array<{ name: string; metadata: any | null }>
  translations: { slug: Array<{ language: string; text: string } | null> | null }
}

export type GetPoiQueryVariables = Exact<{
  slug: Scalars["String"]["input"]
  onlyActive?: Scalars["Boolean"]["input"]
}>

export type GetPoiQuery = {
  poi: {
    id: string
    name: string
    slug: string
    query: string | null
    priority: number
    type: PoiType
    active: boolean
    marketingLanguages: Array<string>
    boilerplateContent: any | null
    coordinates: { latitude: number; longitude: number }
    city: { id: string; slug: string }
    mainStore: {
      id: string
      nickname: string
      gmbPhone: string | null
      gmbLocationName: string
      gmbAddress: {
        addressLines: string
        locality: string | null
        administrativeArea: string | null
        regionCode: string
        postalCode: string | null
      } | null
      coordinates: { latitude: number; longitude: number }
      city: { name: string; state: string | null; country: string }
      address: { street: string; zipCode: string }
      openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
      openingHourExceptions: Array<{
        closeTime: string | null
        closed: boolean
        startDate: string
        endDate: string
        openTime: string | null
        reason: string | null
      }> | null
    } | null
    labels: Array<{ name: string; metadata: any | null }>
    translations: { slug: Array<{ language: string; text: string } | null> | null }
  }
}

export type GetNearbyPoisQueryVariables = Exact<{
  coordinates?: InputMaybe<CoordinatesInput>
  first?: Scalars["Int"]["input"]
  onlyActive?: Scalars["Boolean"]["input"]
}>

export type GetNearbyPoisQuery = {
  pois: {
    edges: Array<{
      node: {
        id: string
        name: string
        slug: string
        coordinates: { latitude: number; longitude: number }
        translations: { slug: Array<{ language: string; text: string } | null> | null }
      }
    }>
  }
}

export type RequestAppBySmsMutationVariables = Exact<{
  phone: Scalars["String"]["input"]
}>

export type RequestAppBySmsMutation = { requestAppLinkText: { status: string } | null }

export type AggregateRatingQueryVariables = Exact<{
  cityId?: InputMaybe<Scalars["String"]["input"]>
}>

export type AggregateRatingQuery = { aggregateRating: { count: number; averageRating: number } }

export type ReviewsQueryVariables = Exact<{
  cityId?: InputMaybe<Scalars["String"]["input"]>
  first: Scalars["Int"]["input"]
  minRating?: InputMaybe<Scalars["Int"]["input"]>
  orderBy?: InputMaybe<Array<InputMaybe<ReviewSortOrder>> | InputMaybe<ReviewSortOrder>>
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>
  withFeedback?: InputMaybe<Scalars["Boolean"]["input"]>
  withTranslatedFeedback: Scalars["Boolean"]["input"]
}>

export type ReviewsQuery = {
  reviews: {
    edges: Array<{
      node: {
        feedback: string | null
        rating: number | null
        id: string
        completedAt: string | null
        translatedFeedback?: string | null
        user: { firstName: string | null; lastName: string | null }
        store: { city: { id: string; slug: string; name: string } }
      }
    }>
  }
}

export type StorageSpotFragment = {
  id: string
  nickname: string
  type: string
  reviewCount: number
  rating: number | null
  slug: string
  price: number
  lowestPrice: number
  city: { currency: string; country: string }
  coordinates: { latitude: number; longitude: number }
}

export type GmbFragmentFragment = {
  gmbExternalId: string | null
  gmbLocationName: string
  gmbLocationPlaceId: string | null
  gmbPhone: string | null
  gmbAddress: {
    addressLines: string
    administrativeArea: string | null
    locality: string | null
    postalCode: string | null
    regionCode: string
  } | null
}

export type MainStoreFragment = {
  id: string
  nickname: string
  gmbPhone: string | null
  gmbLocationName: string
  gmbAddress: {
    addressLines: string
    locality: string | null
    administrativeArea: string | null
    regionCode: string
    postalCode: string | null
  } | null
  coordinates: { latitude: number; longitude: number }
  city: { name: string; state: string | null; country: string }
  address: { street: string; zipCode: string }
}

export type OpeningHourFragment = { openTime: string; closeTime: string; dayOfWeek: number }

export type OpeningHourExceptionFragment = {
  closeTime: string | null
  closed: boolean
  startDate: string
  endDate: string
  openTime: string | null
  reason: string | null
}

export type WithOpeningHoursFragment = {
  openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
  openingHourExceptions: Array<{
    closeTime: string | null
    closed: boolean
    startDate: string
    endDate: string
    openTime: string | null
    reason: string | null
  }> | null
}

export type GetStorageSpotQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>
}>

export type GetStorageSpotQuery = {
  store: {
    distance: number | null
    id: string
    nickname: string
    type: string
    reviewCount: number
    rating: number | null
    slug: string
    price: number
    lowestPrice: number
    city: { currency: string; country: string }
    coordinates: { latitude: number; longitude: number }
    openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
    openingHourExceptions: Array<{
      closeTime: string | null
      closed: boolean
      startDate: string
      endDate: string
      openTime: string | null
      reason: string | null
    }> | null
  }
}

export type GetStoreLocationQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>
  language: Scalars["String"]["input"]
}>

export type GetStoreLocationQuery = {
  store: {
    gmbExternalId: string | null
    gmbLocationName: string
    gmbLocationPlaceId: string | null
    marketingPageEnabled: boolean
    rating: number | null
    reviewCount: number
    id: string
    nickname: string
    type: string
    slug: string
    price: number
    lowestPrice: number
    city: {
      id: string
      country: string
      currency: string
      name: string
      query: string | null
      slug: string
      coordinates: { latitude: number; longitude: number } | null
      translations: { slug: Array<{ language: string; text: string } | null> | null }
    }
    reviews: {
      edges: Array<{
        node: {
          completedAt: string | null
          feedback: string | null
          id: string
          rating: number | null
          translatedFeedback: string | null
          user: { firstName: string | null; lastName: string | null }
        }
      }>
    }
    coordinates: { latitude: number; longitude: number }
    openingHours: Array<{ openTime: string; closeTime: string; dayOfWeek: number }> | null
    openingHourExceptions: Array<{
      closeTime: string | null
      closed: boolean
      startDate: string
      endDate: string
      openTime: string | null
      reason: string | null
    }> | null
  }
}

export type GetNearbyStorageSpotsQueryVariables = Exact<{
  latitude: Scalars["Float"]["input"]
  longitude: Scalars["Float"]["input"]
  first?: InputMaybe<Scalars["Int"]["input"]>
  minRating?: InputMaybe<Scalars["Float"]["input"]>
}>

export type GetNearbyStorageSpotsQuery = {
  stores: {
    edges: Array<{
      node: {
        id: string
        nickname: string
        type: string
        reviewCount: number
        rating: number | null
        slug: string
        price: number
        lowestPrice: number
        city: { currency: string; country: string }
        coordinates: { latitude: number; longitude: number }
      }
    }>
  }
}

export type GetTagsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetTagsQuery = { tags: { edges: Array<{ node: { key: string; name: string } }> } }

export type GetServicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>
}>

export type GetServicesQuery = { services: { edges: Array<{ node: { key: string; name: string } }> } }
