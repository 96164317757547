import React from "react"

import { useReferrals } from "@bounce/referrals"
import type { Nullable } from "@bounce/util"
import { useIsMobile } from "@bounce/web-components"

import { Cities } from "./components/Cities/Cities"
import { Hero } from "./components/Hero"
import { useSEO } from "./hooks/useSEO"
import { config } from "../../../config"
import { StickyCTAProvider } from "../../../contexts/StickyCTAContext"
import { HOMEPAGE_ONLY_LANGUAGES } from "../../../utils/i18n/locale"
import { ReviewsSlider } from "@/components/Generic/Reviews/ReviewsSlider/ReviewsSlider"
import { SearchboxStructuredData } from "@/components/Generic/StructuredData"
import { AppBannerSecondary } from "@/components/LuggageStorage/AppBanner/AppBannerSecondary/AppBannerSecondary"
import { FAQs } from "@/components/LuggageStorage/FAQs/FAQs"
import { HowItWorks } from "@/components/LuggageStorage/HowItWorks/HowItWorks"
import { Layout } from "@/components/LuggageStorage/Layout/Layout"
import NewsletterBanner from "@/components/LuggageStorage/NewsletterBanner/NewsletterBanner"
import { Pricing } from "@/components/LuggageStorage/Pricing/Pricing"
import { WhyUseBounce } from "@/components/LuggageStorage/WhyUseBounce/WhyUseBounce"
import { useGenericInterpolations } from "@/hooks/useGenericInterpolations"
import type { Country } from "@/libs/bounce/bounceSdk.types.gen"
import type { CityLink } from "@/libs/bounce/cities"
import type { AggregateRating } from "@/libs/bounce/types"
import type { FormattedHomepage } from "@/libs/prismic/homepage/types"
import { useUrl } from "@/libs/router"

export type HomeScreenProps = {
  homePage: Nullable<FormattedHomepage>
  cities: CityLink[]
  countries: Country[]
  aggregateRating: AggregateRating
}

const LANGUAGES = Object.values(config.locales)
  .filter(locale => locale.active || HOMEPAGE_ONLY_LANGUAGES.includes(locale.language))
  .map(locale => locale.language)

const HomeScreen = ({ homePage, cities, countries, aggregateRating }: HomeScreenProps) => {
  const { absolutePath } = useUrl()
  const interpolations = useGenericInterpolations({ countries })
  const { metaTitle, metaDescription } = useSEO({ homePage, interpolations })
  const isMobile = useIsMobile()
  const { coupon } = useReferrals()

  return (
    <StickyCTAProvider config={{ from: "Home", coupon }}>
      <Layout
        headProps={{
          title: metaTitle,
          description: metaDescription,
          url: absolutePath,
        }}
        from="Home"
        languages={LANGUAGES}
        hideNewsletterBanner>
        <Hero interpolations={interpolations} aggregateRating={aggregateRating} />
        <ReviewsSlider name="ReviewsSliderHome" className="pt-12 pb-section xl:pt-32" />
        <HowItWorks variant={isMobile ? "app-download" : "find-closest-location"} from="Home" />
        <AppBannerSecondary variant="find-closest-location" titleClassName="md:mt-16" />
        <WhyUseBounce className="py-section" />
        <Pricing countries={countries} defaultCountry={interpolations.COUNTRY_CODE} />
        <NewsletterBanner wrapperClass="w-full pt-section" />
        <FAQs className="pt-section" />
        <Cities cities={cities} />
        <SearchboxStructuredData />
      </Layout>
    </StickyCTAProvider>
  )
}

export default HomeScreen
